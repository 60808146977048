.App {
  display: flex;
  flex-direction: column;
  max-width: 960px;
}

.DadPic {
  background-image: url("/images/dad.png");
  min-height: 500px;
  background-position: 50%;
  background-size: cover;
}

