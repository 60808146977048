body {
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 10px;
  font-size: 20px;
  background-color: black;
}

.Inner {
  background-color: #fffefe;
  max-width: 960px;
  margin: auto;
  margin-top:-10px;
  padding-top: 20px;  
  padding-bottom: 500px;

}

.Memory {
  margin: 20px;
}

.Memory div{
  background-color: #faffff;
}

a {
  text-decoration: none; 
  color: #336699;
}

ul {
  text-align: left;
  font-size: 16px;  
}
ul li {
  text-decoration: none;
  list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display";
  padding: 0em;
  margin: 0px;
  font-weight: normal;
}
